<template>
    <!-- Portfolio main screen -->
    <v-container>
        <v-row align="center" justify="center" class="text-center pa-lg-16 pt-8 px-4">
            <p class="brightRoyalBlue--text customTitle">
                {{ portfolio[0]['title'] }}</p>
        </v-row>

        <!-- CONTENT -->
        <v-row align="center" justify="center" class="mb-16 pb-16">
            <!-- CONTENT WIDTH -->
            <v-col cols="10">
                <v-row align="center" justify="center">
                    <v-col cols="12">
                        <PortfolioList></PortfolioList>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PortfolioList from '@/components/portfolio/Portfolio_List'
import * as db from "@/constants/portfolio_database";

export default {
    name: "PortfolioScreen",
    components: {PortfolioList},
    data() {
        return {
            portfolio: null,
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        }
    },
    beforeMount() {
        //Checks store value to import localized text
        if (this.language === 'es') {
            this.portfolio = db.portfolio_titles_es;
        } else {
            this.portfolio = db.portfolio_titles_en;
        }
    },

}
</script>

<style scoped>
</style>