<template>
    <!-- Card structure -->
    <v-hover v-slot="{ hover }">
        <v-container class="fill-height pa-3">
            <v-row align="center" dense no-gutters>
                <v-col cols="12">
                    <v-card
                        @click="portfolioDetails(title, select)"
                        class="rounded-card pa-5 "
                        height="100%"
                        id="shadow"
                        outlined
                        :elevation="hover ? 12: 4"
                    >
                        <v-row justify="center" no-gutters>
                            <img :src="require(`../../assets/portfolio/${image}`)" height="120px" class="text-align center" alt="">
                        </v-row>

                        <v-row class="pa-1 pb-10 ma-3 pt-0" no-gutters>
                            <v-card-title class="questionSubtitle text-start">
                                {{ title }}
                            </v-card-title>
                            <v-card-subtitle class="viewText text-start">
                                <div>
                                    {{ description }}
                                </div>
                            </v-card-subtitle>
                        </v-row>

                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-hover>
</template>

<script>
export default {
    name: "PortfolioCard",
    props: {
        title: String,
        description: String,
        image: String,
        select: String
    },
    methods: {
        //Send generated id from "Portfolio_List" to "Portfolio_Product" in order to display selected content
        portfolioDetails(product, select) {
            this.$router.push({path: "portfolio/" + product + "/" + select, params: {id: select}})
        }
    }
}
</script>

<style scoped>
.rounded-card {
    border-radius: 25px;
    border-color: gray;
}

#shadow {
    box-shadow: 2px 2px 3px 3px lightgrey;
}

.questionSubtitle {
    font-family: Montserrat, sans-serif;
    font-size: 100%;
    font-weight: bold;
    color: dimgrey;
}

.viewText {
    font-family: Montserrat, sans-serif;
    font-size: 100%;
    color: #808080;
}
</style>