<template>
    <!-- Portfolio_List display all projects as independent cards -->
    <v-row no-gutters>
        <v-col
            v-for="(item, index) in products" v-bind:key="item + '-' + index"
            cols="12" md="4" sm="6"
            class="pa-1 mb-6"
        >
            <!-- Include different padding to 2nd column cards -->
            <div class="pt-lg-12 pt-0" v-if="index === 1"></div>
            <div class="pb-lg-12 pt-0" v-if="index === 4"></div>

            <!-- The "select" prop is an internal id to identify all information related to the project-->
            <PortfolioCard
                :title="products[index]['title']"
                :description="products[index]['preview']"
                :image="products[index]['imgLogo']"
                :select="index.toString()">
            </PortfolioCard>

        </v-col>
    </v-row>
</template>

<script>
import PortfolioCard from '@/components/cards/Portfolio_Card'
import * as db_local from "@/constants/portfolio_database";

export default {
    name: "PortfolioList",
    components: {
        PortfolioCard
    },
    data() {
        return {
            products: []
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        }
    },
    beforeMount() {
        //import home database
        if (this.language === 'es') {
            this.products = db_local.portfolio_info_es;
        } else {
            this.products = db_local.portfolio_info_en;
        }
    },
}
</script>

<style scoped>

</style>