export const portfolio_info_es = [
    {
        title: "Kuida",
        needAnswer: "Cuando Kuida llegó con nosotros, era únicamente un concepto que se quería ejecutar, necesitaban ayuda para nombrar su marca, " +
            "imagen corporativa, aterrizar la idea de la app y desarrollar todos los productos digitales que componen hoy en día a la plataforma que " +
            "busca facilitar la operación de los especialistas de la salud y conectarlos con sus pacientes.",
        solutionAnswer: "Nuestra tarea fue brindar un servicio integral que permitiera a Kuida alcanzar sus objetivos en todas las áreas que la " +
            "empresa lo necesitaba. Se desarrollaron 4 apps que componen el ecosistema; Mobile App para Pacientes (Android & iOS), Progressive Web " +
            "App para Pacientes, Web App para Doctores & Web App para Administradores. De igual manera creamos todo un sistema de marca para Kuida, " +
            "el cual se utiliza en su website y todo su material gráfico para campañas y comunicación interna de la empresa. Kuida es una startup 100% " +
            "mexicana con gran oportunidad de crecimiento llamando la atención de empresas como Apple y el Tec de Monterrey para colaborar con ellos " +
            "gracias al impacto que han tenido con los proyectos que hemos desarrollado en conjunto.",
        preview: "Kuida es el asistente perfecto para doctores, psicólogos, nutriólogos y más. una start up 100% mexicana que conecta especialistas " +
            "de la salud con pacientes.",
        imgLogo: "Ideen_Kuida.png",
        link: "kuida.io",
        description: "Asistente virtual para especialistas de la salud.",
        fieldAnswer: "Start Up / Salud",
        serviceAnswer: "Mobile Apps, Web Apps, Website, Marketing & Branding",
        carouselImages: [
            "kuida/Portafolio_Kuida_1.png",
            "kuida/Portafolio Kuida 2.png",
            "kuida/Portafolio Kuida 3.png",
            "kuida/Portafolio Kuida 4.png",
            "kuida/Portafolio Kuida 5.png",
            "kuida/Portafolio Kuida 6.png",
        ],
    },
    {
        title: "Patriot Aviation",
        needAnswer: "Patriot Aviation es una empresa 100% mexicana que ofrece servicio de vuelos privados con jets y helicópteros, operan en toda " +
            "la república mexicana e incluso en el extranjero para siempre ofrecer el mejor servicio en el mercado para clientes de alto nivel. " +
            "Ellos tenían la necesidad de optimizar su impacto en diferentes canales digitales.",
        solutionAnswer: "Una vez que conocimos las necesidades que Patriot Aviation tenía, se comenzó la planeación y diseño de campañas de marketing " +
            "digital multicanal que le permitiera a la empresa contactar con su segmento de mercado, que es muy específico. Se han implementado " +
            "campañas de Programmatic, Social Media, Email Marketing y Newspaper para llamar la atención de clientes potenciales en diferentes " +
            "lugares o momentos. Estas implementaciones han ayudado en gran parte a que Patriot Aviation logre alcanzar los objetivos de " +
            "captación de prospectos que tenía en mente.",
        preview: "Patriot Aviation es una aerolínea de vuelos privados nacionales e internacionales, con sedes en diferentes ciudades dentro del territorio mexicano.",
        imgLogo: "Ideen_Patriot.png",
        link: "patriotaviation.mx",
        description: "Renta de areonaves privadas para vuelos nacionales e internacionales.",
        fieldAnswer: "Servicios de Lujo",
        serviceAnswer: "Campañas orgánicas y pautadas de marketing.",
        carouselImages: [
            "patriot/Portafolio Patriot_1.png",
            "patriot/Portafolio Patriot_2.png",
            "patriot/Portafolio Patriot_3.png",
            "patriot/Portafolio Patriot_4.png",
            "patriot/Portafolio Patriot_5.png",
        ]
    },
    {
        title: "Mezcla Brava",
        needAnswer: "Las mezclas listas para construcción son productos que comienzan a generar impacto y ruido en Latinoamérica gracias al trabajo" +
            " que ha realizado Mezcla Brava en los últimos 5 años. Al tener una gama de productos modernos, simples y eficientes, Mezcla Brava " +
            "necesitaba un canal de comunicación que permitiera al público conocer todos los productos y servicios que ofrecen, así como, la " +
            "cobertura que tienen dentro de México para entrega de materiales con su cadena de distribución.",
        solutionAnswer: "Para ayudar a lograr los objetivos de comunicación que nos presentó el equipo de Mezcla Brava decidimos crear un website " +
            "totalmente nuevo que ayudara a compartir toda la información respecto a sus productos, servicios, tutoriales de producto y maquinaria, lista de " +
            "distribuidores y el bien conocido 'Sistema Constructivo Mezcla Brava' en un solo lugar, simple y digerible para todo el público. Por " +
            "otro lado, una vez que se desarrolló el website se han empleado y implementado campañas de marketing en más de 5 canales para hacer promoción " +
            "de sus productos y eventos. Estas soluciones han sido una excelente combinación que han ayudado a Mezcla Brava a alcanzar los objetivos " +
            "planeados que tenían antes de comenzar los proyectos en conjunto.",
        preview: "Mezclas listas para cualquier etapa de construcción líderes en México. Empresa de Grupo Calidra que está revolucionando la construcción en el país.",
        imgLogo: "Ideen_Mezcla Brava.png",
        link: "mezclabrava.com",
        description: "Mezclas listas para construcción.",
        fieldAnswer: "Construcción",
        serviceAnswer: "Website, Campañas orgánicas y pautadas de marketing & Diseño Gráfico.",
        carouselImages: [
            "mezcla_brava/Portafolio Mezcla Brava_1.png",
            "mezcla_brava/Portafolio Mezcla Brava_2.png",
            "mezcla_brava/Portafolio Mezcla Brava_3.png",
            "mezcla_brava/Portafolio Mezcla Brava_4.png",
            "mezcla_brava/Portafolio Mezcla Brava_5.png",
        ]
    },
    {
        title: "Kontakt",
        needAnswer: "Los artículos para práctica casual o profesional de artes marciales históricamente han sido un poco complicados de comprar en México, especialmente productos de marcas de alta calidad como Adidas. Es por esto que Kontakt estaba buscando alguna manera en la que pudieran comercializar sus productos desde cualquier parte del país.",
        solutionAnswer: "Con la intención de crear una solución a la necesidad de poder comprar artículos para artes marciales de Adidas en cualquier parte del país, Kontakt e ideen trabajaron en conjunto para desarrollar un e-commerce que aprovecha la tecnología de Shopify para poder generar una experiencia de compra única desde la comodidad de tu casa. De esta manera el personal de Kontakt tiene las herramientas necesarias para gestionar la información de sus clientes, ventas, productos, inventarios, servicios de paquetería, promociones e incluso tarjetas de regalo. Todo esto apoyado por una campaña de marketing digital multicanal que ha permitido que Kontakt se colocara en solo unas semanas dentro del top 100 de e-commerce con mejor rendimiento en México dentro del ecosistema de tecnologías de Shopify.",
        preview: "Distribuidores oficiales de Adidas Martial Arts en México que han innovado la manera de comprar los productos para práctica de artes marciales",
        imgLogo: "Ideen_Kontakt.png",
        link: "kontakt.mx",
        description: "E-commerce para distribuidor oficial de Adidas.",
        fieldAnswer: "Retail / Deportes",
        serviceAnswer: "E-commerce, Marketing & Branding",
        carouselImages: [
            "kontakt/Portafolio Kontakt_1.png",
            "kontakt/Portafolio Kontakt_2.png",
            "kontakt/Portafolio Kontakt_3.png",
            "kontakt/Portafolio Kontakt_4.png",
            "kontakt/Portafolio Kontakt_5.png",
        ]
    },
    {
        title: "Milo Health",
        needAnswer: "Los insumos médicos han sido productos que se han requerido desde hace muchos años por parte de hospitales y consultorios, pero " +
            "a raíz de la pandemia incrementó de manera exponencial la necesidad de este tipo de productos en nuestras casas. Milo Health necesitaba" +
            " encontrar una manera fácil y eficiente en la que la gente como tú y nosotros pudiéramos tener acceso a comprar productos como cubrebocas, " +
            "tanques, artículos anti-bacteriales, etc. en menudeo a precios razonables.",
        solutionAnswer: "ideen ayudó a Milo Health a crear una plataforma en la que la gente pudiera comprar estos productos en cualquier parte de " +
            "la República Mexicana sin importar las cantidades o dimensiones de sus pedidos, preparamos un e-commerce que tuviera la capacidad de" +
            " dejar todo listo para conectar los pedidos generados por los clientes con las soluciones logísticas con las que ya contaban. Para " +
            "todo esto utilizamos la suite de productos y servicios que ofrecemos como Shopify Partners que nos ayuda a generar experiencias de" +
            " compra únicas sin importar la industria, el tipo de producto que comercialices o la ubicación del negocio.",
        preview: "Comercializadora de insumos y dispositivos médicos especializados para público en general y hospitales con cobertura nacional.",
        imgLogo: "Ideen_Milo Health.png",
        link: "milohealth.com.mx",
        description: "Empresa dedicada a venta de insumos médicos online.",
        fieldAnswer: "Retail / Salud",
        serviceAnswer: "Branding & E-commerce",
        carouselImages: [
            "milo_health/Portafolio MiloHealth_1.png",
            "milo_health/Portafolio MiloHealth_2.png",
            "milo_health/Portafolio MiloHealth_3.png",
            "milo_health/Portafolio MiloHealth_4.png",
        ]
    },
    {
        title: "Lacandonia",
        needAnswer: "Lacandonia Abogados Corporativos & Ambientales es una empresa con más de 10 años de experiencia dando consultoría y resolviendo" +
            " conflictos legales en materia ambiental, corporativa, inmobiliaria & migratoria, ganando respeto y creciendo su cartera de clientes al " +
            "rededor de México, USA & Canadá. Después de todo el crecimiento alcanzado, identificaron que necesitaban renovar su imágen para " +
            "actualizar la percepción del mercado y tener una carta de presentación óptima para las necesidades actuales de los clientes potenciales " +
            "de la consultoría.",
        solutionAnswer: "Tomando en cuenta la filosofía e historia de Lacandonia, desarrollamos un sistema de marca que comunicara frescura, seriedad, " +
            "modernidad y los valores de la empresa. Esta nueva imagen de la empresa es utilizada en todos sus documentos oficiales y de contacto." +
            " Por otro lado, desarrollamos un website que estuviera adaptado al mercado hispanohablante y anglosajón que esté interesado en conocer " +
            "acerca de la empresa y los servicios en los que están especializados.",
        preview: "Despacho de abogados especialistas en derecho ambiental, corporativo, inmobiliario y migratorio con presencia en todo Norte América.",
        imgLogo: "Ideen_Lacandonia.png",
        link: "lacandonia.com.mx",
        description: "Renovación de imagen de despacho de abogados especialistas en derecho ambiental, corporativo, inmobiliario y migratorio.",
        fieldAnswer: "Consultoría / Abogados",
        serviceAnswer: "Website & Branding",
        carouselImages: [
            "lacandonia/Portafolio Lacandonia_1.png",
            "lacandonia/Portafolio Lacandonia_2.png",
            "lacandonia/Portafolio Lacandonia_3.png",
            "lacandonia/Portafolio Lacandonia_4.png",
        ]
    }
]


export const portfolio_titles_es = [
    {
        need: "Su necesidad",
        solution: "La solución",
        field: "Sector",
        service: "Servicio brindado",
        development: "Desarrollo",
        title: "Algunos de nuestros proyectos"
    }
]


export const portfolio_info_en = [
    {
        title: "Kuida",
        needAnswer: "Cuando Kuida llegó con nosotros, era únicamente un concepto que se quería ejecutar, necesitaban ayuda para nombrar su marca, " +
          "imagen corporativa, aterrizar la idea de la app y desarrollar todos los productos digitales que componen hoy en día a la plataforma que " +
          "busca facilitar la operación de los especialistas de la salud y conectarlos con sus pacientes.",
        solutionAnswer: "Nuestra tarea fue brindar un servicio integral que permitiera a Kuida alcanzar sus objetivos en todas las áreas que la " +
          "empresa lo necesitaba. Se desarrollaron 4 apps que componen el ecosistema; Mobile App para Pacientes (Android & iOS), Progressive Web " +
          "App para Pacientes, Web App para Doctores & Web App para Administradores. De igual manera creamos todo un sistema de marca para Kuida, " +
          "el cual se utiliza en su website y todo su material gráfico para campañas y comunicación interna de la empresa. Kuida es una startup 100% " +
          "mexicana con gran oportunidad de crecimiento llamando la atención de empresas como Apple y el Tec de Monterrey para colaborar con ellos " +
          "gracias al impacto que han tenido con los proyectos que hemos desarrollado en conjunto.",
        preview: "Kuida es el asistente perfecto para doctores, psicólogos, nutriólogos y más. una start up 100% mexicana que conecta especialistas " +
          "de la salud con pacientes.",
        imgLogo: "Ideen_Kuida.png",
        link: "kuida.io",
        description: "Asistente virtual para especialistas de la salud.",
        fieldAnswer: "Start Up / Salud",
        serviceAnswer: "Mobile Apps, Web Apps, Website, Marketing & Branding",
        carouselImages: [
            "kuida/Portafolio_Kuida_1.png",
            "kuida/Portafolio Kuida 2.png",
            "kuida/Portafolio Kuida 3.png",
            "kuida/Portafolio Kuida 4.png",
            "kuida/Portafolio Kuida 5.png",
            "kuida/Portafolio Kuida 6.png",
        ],
    },
    {
        title: "Patriot Aviation",
        needAnswer: "Patriot Aviation es una empresa 100% mexicana que ofrece servicio de vuelos privados con jets y helicópteros, operan en toda " +
          "la república mexicana e incluso en el extranjero para siempre ofrecer el mejor servicio en el mercado para clientes de alto nivel. " +
          "Ellos tenían la necesidad de optimizar su impacto en diferentes canales digitales.",
        solutionAnswer: "Una vez que conocimos las necesidades que Patriot Aviation tenía, se comenzó la planeación y diseño de campañas de marketing " +
          "digital multicanal que le permitiera a la empresa contactar con su segmento de mercado, que es muy específico. Se han implementado " +
          "campañas de Programmatic, Social Media, Email Marketing y Newspaper para llamar la atención de clientes potenciales en diferentes " +
          "lugares o momentos. Estas implementaciones han ayudado en gran parte a que Patriot Aviation logre alcanzar los objetivos de " +
          "captación de prospectos que tenía en mente.",
        preview: "Patriot Aviation es una aerolínea de vuelos privados nacionales e internacionales, con sedes en diferentes ciudades dentro del territorio mexicano.",
        imgLogo: "Ideen_Patriot.png",
        link: "patriotaviation.mx",
        description: "Renta de areonaves privadas para vuelos nacionales e internacionales.",
        fieldAnswer: "Servicios de Lujo",
        serviceAnswer: "Campañas orgánicas y pautadas de marketing.",
        carouselImages: [
            "patriot/Portafolio Patriot_1.png",
            "patriot/Portafolio Patriot_2.png",
            "patriot/Portafolio Patriot_3.png",
            "patriot/Portafolio Patriot_4.png",
            "patriot/Portafolio Patriot_5.png",
        ]
    },
    {
        title: "Mezcla Brava",
        needAnswer: "Las mezclas listas para construcción son productos que comienzan a generar impacto y ruido en Latinoamérica gracias al trabajo" +
          " que ha realizado Mezcla Brava en los últimos 5 años. Al tener una gama de productos modernos, simples y eficientes, Mezcla Brava " +
          "necesitaba un canal de comunicación que permitiera al público conocer todos los productos y servicios que ofrecen, así como, la " +
          "cobertura que tienen dentro de México para entrega de materiales con su cadena de distribución.",
        solutionAnswer: "Para ayudar a lograr los objetivos de comunicación que nos presentó el equipo de Mezcla Brava decidimos crear un website " +
          "totalmente nuevo que ayudara a compartir toda la información respecto a sus productos, servicios, tutoriales de producto y maquinaria, lista de " +
          "distribuidores y el bien conocido 'Sistema Constructivo Mezcla Brava' en un solo lugar, simple y digerible para todo el público. Por " +
          "otro lado, una vez que se desarrolló el website se han empleado y implementado campañas de marketing en más de 5 canales para hacer promoción " +
          "de sus productos y eventos. Estas soluciones han sido una excelente combinación que han ayudado a Mezcla Brava a alcanzar los objetivos " +
          "planeados que tenían antes de comenzar los proyectos en conjunto.",
        preview: "Mezclas listas para cualquier etapa de construcción líderes en México. Empresa de Grupo Calidra que está revolucionando la construcción en el país.",
        imgLogo: "Ideen_Mezcla Brava.png",
        link: "mezclabrava.com",
        description: "Mezclas listas para construcción.",
        fieldAnswer: "Construcción",
        serviceAnswer: "Website, Campañas orgánicas y pautadas de marketing & Diseño Gráfico.",
        carouselImages: [
            "mezcla_brava/Portafolio Mezcla Brava_1.png",
            "mezcla_brava/Portafolio Mezcla Brava_2.png",
            "mezcla_brava/Portafolio Mezcla Brava_3.png",
            "mezcla_brava/Portafolio Mezcla Brava_4.png",
            "mezcla_brava/Portafolio Mezcla Brava_5.png",
        ]
    },
    {
        title: "Kontakt",
        needAnswer: "Los artículos para práctica casual o profesional de artes marciales históricamente han sido un poco complicados de comprar en México, especialmente productos de marcas de alta calidad como Adidas. Es por esto que Kontakt estaba buscando alguna manera en la que pudieran comercializar sus productos desde cualquier parte del país.",
        solutionAnswer: "Con la intención de crear una solución a la necesidad de poder comprar artículos para artes marciales de Adidas en cualquier parte del país, Kontakt e ideen trabajaron en conjunto para desarrollar un e-commerce que aprovecha la tecnología de Shopify para poder generar una experiencia de compra única desde la comodidad de tu casa. De esta manera el personal de Kontakt tiene las herramientas necesarias para gestionar la información de sus clientes, ventas, productos, inventarios, servicios de paquetería, promociones e incluso tarjetas de regalo. Todo esto apoyado por una campaña de marketing digital multicanal que ha permitido que Kontakt se colocara en solo unas semanas dentro del top 100 de e-commerce con mejor rendimiento en México dentro del ecosistema de tecnologías de Shopify.",
        preview: "Distribuidores oficiales de Adidas Martial Arts en México que han innovado la manera de comprar los productos para práctica de artes marciales",
        imgLogo: "Ideen_Kontakt.png",
        link: "kontakt.mx",
        description: "E-commerce para distribuidor oficial de Adidas.",
        fieldAnswer: "Retail / Deportes",
        serviceAnswer: "E-commerce, Marketing & Branding",
        carouselImages: [
            "kontakt/Portafolio Kontakt_1.png",
            "kontakt/Portafolio Kontakt_2.png",
            "kontakt/Portafolio Kontakt_3.png",
            "kontakt/Portafolio Kontakt_4.png",
            "kontakt/Portafolio Kontakt_5.png",
        ]
    },
    {
        title: "Milo Health",
        needAnswer: "Los insumos médicos han sido productos que se han requerido desde hace muchos años por parte de hospitales y consultorios, pero " +
          "a raíz de la pandemia incrementó de manera exponencial la necesidad de este tipo de productos en nuestras casas. Milo Health necesitaba" +
          " encontrar una manera fácil y eficiente en la que la gente como tú y nosotros pudiéramos tener acceso a comprar productos como cubrebocas, " +
          "tanques, artículos anti-bacteriales, etc. en menudeo a precios razonables.",
        solutionAnswer: "ideen ayudó a Milo Health a crear una plataforma en la que la gente pudiera comprar estos productos en cualquier parte de " +
          "la República Mexicana sin importar las cantidades o dimensiones de sus pedidos, preparamos un e-commerce que tuviera la capacidad de" +
          " dejar todo listo para conectar los pedidos generados por los clientes con las soluciones logísticas con las que ya contaban. Para " +
          "todo esto utilizamos la suite de productos y servicios que ofrecemos como Shopify Partners que nos ayuda a generar experiencias de" +
          " compra únicas sin importar la industria, el tipo de producto que comercialices o la ubicación del negocio.",
        preview: "Comercializadora de insumos y dispositivos médicos especializados para público en general y hospitales con cobertura nacional.",
        imgLogo: "Ideen_Milo Health.png",
        link: "milohealth.com.mx",
        description: "Empresa dedicada a venta de insumos médicos online.",
        fieldAnswer: "Retail / Salud",
        serviceAnswer: "Branding & E-commerce",
        carouselImages: [
            "milo_health/Portafolio MiloHealth_1.png",
            "milo_health/Portafolio MiloHealth_2.png",
            "milo_health/Portafolio MiloHealth_3.png",
            "milo_health/Portafolio MiloHealth_4.png",
        ]
    },
    {
        title: "Lacandonia",
        needAnswer: "Lacandonia Abogados Corporativos & Ambientales es una empresa con más de 10 años de experiencia dando consultoría y resolviendo" +
          " conflictos legales en materia ambiental, corporativa, inmobiliaria & migratoria, ganando respeto y creciendo su cartera de clientes al " +
          "rededor de México, USA & Canadá. Después de todo el crecimiento alcanzado, identificaron que necesitaban renovar su imágen para " +
          "actualizar la percepción del mercado y tener una carta de presentación óptima para las necesidades actuales de los clientes potenciales " +
          "de la consultoría.",
        solutionAnswer: "Tomando en cuenta la filosofía e historia de Lacandonia, desarrollamos un sistema de marca que comunicara frescura, seriedad, " +
          "modernidad y los valores de la empresa. Esta nueva imagen de la empresa es utilizada en todos sus documentos oficiales y de contacto." +
          " Por otro lado, desarrollamos un website que estuviera adaptado al mercado hispanohablante y anglosajón que esté interesado en conocer " +
          "acerca de la empresa y los servicios en los que están especializados.",
        preview: "Despacho de abogados especialistas en derecho ambiental, corporativo, inmobiliario y migratorio con presencia en todo Norte América.",
        imgLogo: "Ideen_Lacandonia.png",
        link: "lacandonia.com.mx",
        description: "Renovación de imagen de despacho de abogados especialistas en derecho ambiental, corporativo, inmobiliario y migratorio.",
        fieldAnswer: "Consultoría / Abogados",
        serviceAnswer: "Website & Branding",
        carouselImages: [
            "lacandonia/Portafolio Lacandonia_1.png",
            "lacandonia/Portafolio Lacandonia_2.png",
            "lacandonia/Portafolio Lacandonia_3.png",
            "lacandonia/Portafolio Lacandonia_4.png",
        ]
    }
]


export const portfolio_titles_en = [
    {
        need: "Their need",
        solution: "Solution",
        field: "Industry",
        service: "Given Services",
        development: "Developed",
        title: "Some projects for our customers"
    }
]